import isEqual from 'lodash/isEqual';
import extend from 'lodash/extend';
import {GenericObject} from '@kwixl/elements';

export const reducer = (
  currentState: GenericObject,
  payload: GenericObject
) => {
  // Objects are the same, just return current state
  if (isEqual(currentState, payload)) {
    return currentState;
  }
  return extend({}, currentState, payload);
};
